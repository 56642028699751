import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useEffect, useRef, useState } from 'react'

import { Props as ServiceProps, Service } from './Service'

const AUTOPLAY_TIMER = 9995000

export interface Props {
  cta?: ButtonProps
  description?: string
  services: ServiceProps[]
  subtitle?: string
  title?: string
}

export const ActivityPreview = memo(function ActivityPreview({
  cta,
  description,
  services,
  subtitle,
  title,
}: Props) {
  if (services.length < 1) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)
  const timer = useRef<any>()

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: services.length > 3 ? true : false,
    slides: {
      perView: 3,
      spacing: 24,
    },
    animationEnded(s) {
      setCurrentSlide(s.track.details.rel)
    },
    breakpoints: {
      '(max-width: 1123px)': {
        slides: {
          perView: 2.3,
          spacing: 24,
        },
      },
      '(max-width: 767px)': {
        slides: {
          perView: 1.01,
          spacing: 16,
        },
      },
    },
  })

  useEffect(() => {
    timer.current = setInterval(() => {
      if (instanceRef) {
        instanceRef.current?.next()
      }
    }, AUTOPLAY_TIMER)

    return () => {
      clearInterval(timer.current)
    }
  }, [currentSlide, instanceRef, sliderRef])

  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}
      {subtitle ? (
        <FadeInUp>
          <Subtitle>{subtitle}</Subtitle>
        </FadeInUp>
      ) : null}

      <Slider ref={sliderRef}>
        {services.map((item, index) => (
          <Service className="keen-slider__slide" key={index} {...item} />
        ))}
      </Slider>

      {services.length > 3 ? (
        <Dots dial={2} row>
          {services.map((item, index) => (
            <Dot
              key={index}
              className={currentSlide === index ? 'active' : undefined}
              onClick={() => {
                instanceRef.current?.moveToIdx(index)
              }}
            />
          ))}
        </Dots>
      ) : null}

      {description ? (
        <FadeInUp>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </FadeInUp>
      ) : null}
      {cta ? (
        <FadeInUp>
          <CTA {...cta} />
        </FadeInUp>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  margin-top: 10.875rem;
  overflow: hidden;
  padding: 0 6.667vw 5.8125rem;
  position: relative;
  text-align: center;
  &:after {
    content: '';
    width: 100%;
    height: 65%;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  @media (max-width: 1023px) {
    margin-top: 7.5rem;
    padding: 0 1.875rem 5rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 300;
  letter-spacing: 0.1062rem;
  line-height: 1.25rem;
  margin-bottom: 1.875rem;

  @media (max-width: 1023px) {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
`

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryLight3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.6875rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 2.5rem;
    line-height: 2.9375rem;
  }
`

const Slider = styled.div`
  display: flex;
  overflow: hidden;
  padding-top: 3.75rem;
  margin-top: 3.75rem;
  &[data-keen-slider-moves] {
    a {
      pointer-events: none;
    }
  }

  @media (max-width: 1023px) {
    overflow: visible;
    padding-top: 0;
  }
`

const Dots = styled(FlexBox)`
  margin: 3.25rem auto 0;

  @media (max-width: 1023px) {
    display: none;
  }
`

const Dot = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  background: ${({ theme }) => theme.colors.variants.secondaryDark3};
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.3;
  margin: 0 0.625rem;
  &.active {
    opacity: 1;
    pointer-events: none;
  }
`

const Description = styled.div`
  max-width: 38.25rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  font-weight: 300;
  line-height: 2rem;
  margin: 4.75rem auto 0;

  @media (max-width: 1023px) {
    margin-top: 3rem;
  }
`

const CTA = styled(Button)`
  margin-top: 3rem;
`
