import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { ChevronRight } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  buttonLabel?: string
  buttonURL?: string
  image?: ImageProps
  title?: string
}

export const Service = memo(function Service({
  buttonLabel,
  buttonURL,
  image,
  title,
}: Props) {
  return (
    <Container to={buttonURL || '#'}>
      {image ? (
        <LazyLoadComponent>
          <Image {...image} />
        </LazyLoadComponent>
      ) : null}
      {title ? <Title className="service-title">{title}</Title> : null}
      {buttonLabel ? (
        <Button className="service-button" dial={4} inline tag="span" row>
          {buttonLabel}
          <ChevronRight />
        </Button>
      ) : null}
    </Container>
  )
})

const Container = styled(Link)`
  width: 33.333%;
  height: 70vh;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  position: relative;
  text-align: left;
  text-transform: uppercase;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    z-index: 2;
  }
  &:hover {
    img {
      transform: scale(1.1);
    }
    .service-wrap {
      transform: none;
    }
    .service-title {
      transform: translateY(-3.875rem);
    }
    .service-button {
      opacity: 1;
      &:after {
        right: 0;
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(1);
    transition: all 0.3s ease-in-out !important;
  }

  @media (max-width: 1023px) {
    width: 100%;
    height: auto;
    padding: 5rem 1.875rem 4.375rem;
    text-align: center;
    &:hover {
      img,
      .service-title {
        transform: none;
      }
    }
  }
`

const Title = styled(FlexBox)`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 1.75rem;
  position: absolute;
  bottom: 3.75rem;
  right: 3.75rem;
  left: 3.75rem;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  z-index: 2;

  @media (max-width: 1023px) {
    align-items: center;
    position: relative;
    bottom: auto;
    right: auto;
    left: auto;
  }
`

const Button = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.secondaryDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: 0.0469rem;
  line-height: 1.1875rem;
  opacity: 0;
  padding: 0.375rem 0.6875rem;
  position: absolute;
  bottom: 3.75rem;
  left: 3.75rem;
  transition: 0.3s ease-in-out;
  white-space: nowrap;
  z-index: 2;

  svg {
    width: 0.75rem;
    height: auto;
    fill: none;
    margin-left: 0.75rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-width: 3;
  }

  @media (max-width: 1023px) {
    opacity: 1 !important;
    margin-top: 1.5rem;
    position: relative;
    bottom: auto;
    left: auto;
  }
`
