import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'

export interface Props {
  icon?: string
  label?: string
}

export const Service = memo(function Service({ icon, label }: Props) {
  if (!icon || !label) {
    return null
  }

  const [ref, isVisible] = useInView({ threshold: 0.25 })
  const variants = {
    visible: {
      duration: 0.6,
      opacity: 1,
      y: 0,
    },
    hidden: {
      opacity: 0,
      y: '3.75rem',
    },
  }

  return (
    <Container
      ref={ref}
      animate={isVisible ? 'visible' : 'hidden'}
      transition={{
        duration: 0.5,
      }}
      variants={variants}
    >
      {icon ? <Icon src={icon} alt={label} width="40" height="40" /> : null}
      {label ? <Label>{label}</Label> : null}
    </Container>
  )
})

const Container = styled(motion.div)`
  display: flex;
  align-items: center;
  width: 50%;
  min-height: 2.5rem;
  margin-top: 2.5rem;

  @media (max-width: 767px) {
    width: 100%;
  }
`

const Icon = styled.img`
  width: 2.5rem;
  height: auto;
  margin-right: 1rem;
`

const Label = styled.div`
  width: calc(100% - 3.5rem);
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  font-weight: 300;
  line-height: 2rem;
  padding-right: 1.875rem;
`
