import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  services: ServiceProps[]
  subtitle?: string
  title?: string
}

export const ServicesPreview = memo(function ServicesPreview({
  services,
  subtitle,
  title,
}: Props) {
  if (services.length < 1) {
    return null
  }

  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}
      {subtitle ? (
        <FadeInUp>
          <Subtitle>{subtitle}</Subtitle>
        </FadeInUp>
      ) : null}
      <Services row wrap>
        {services.slice(0, 3).map((item, index) => (
          <Service key={index} {...item} />
        ))}
      </Services>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.primaryLight3};
  padding-top: 7.5rem;
  text-align: center;

  @media (max-width: 1023px) {
    padding-top: 3.75rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 300;
  letter-spacing: 0.1062rem;
  line-height: 1.25rem;
  padding: 0 1.875rem;

  @media (max-width: 1023px) {
    font-size: 1rem;
  }
`

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.125rem;
  margin-top: 1.875rem;
  padding: 0 1.875rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 2.5rem;
    line-height: 2.9375rem;
    margin-top: 1.5rem;
  }
`

const Services = styled(FlexBox)`
  margin-top: 7.1875rem;

  @media (max-width: 1023px) {
    margin-top: 3rem;
  }
`
