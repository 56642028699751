import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  backgroundImage?: ImageProps
  claim?: string
  cta?: ButtonProps
  description?: string
  mainImage?: ImageProps
  title?: string
}

export const VisualServices = memo(function VisualServices({
  backgroundImage,
  claim,
  cta,
  description,
  mainImage,
  title,
}: Props) {
  return (
    <Container>
      {description || title ? (
        <Wrap>
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
          {description ? (
            <FadeInUp>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </FadeInUp>
          ) : null}
        </Wrap>
      ) : null}
      {mainImage ? (
        <MainImage>
          <LazyLoadComponent>
            <Image {...mainImage} />
          </LazyLoadComponent>

          {claim ? <Claim>{claim}</Claim> : undefined}
          {cta ? <CTA {...cta} /> : null}
        </MainImage>
      ) : null}
      {backgroundImage ? (
        <BackgroundImage>
          <LazyLoadComponent>
            <Image {...backgroundImage} />
          </LazyLoadComponent>
        </BackgroundImage>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  margin-top: 9.875rem;
  padding-bottom: 11.125rem;
  position: relative;
  text-align: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    margin-top: 5rem;
    padding-bottom: 3.75rem;
  }
`

const Wrap = styled.div`
  max-width: 45rem;
  margin: auto;
  padding: 0 2.083vw;

  @media (max-width: 1023px) {
    max-width: none;
    padding: 0 1.875rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryLight3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.75rem;
  line-height: 4.375rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 2.5rem;
    line-height: 2.9375rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  font-weight: 300;
  line-height: 2rem;
  margin-top: 1.875rem;
`

const MainImage = styled.div`
  width: calc(100% - 27.778vw);
  margin: 3.75rem auto 0;
  padding-bottom: 40%;
  position: relative;
  z-index: 3;
  &:before {
    content: '';
    width: 100%;
    height: 50%;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark1, 0)},
      ${rgba(theme.colors.variants.neutralDark1, 0.2)}
    );
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
  }

  @media (max-width: 1023px) {
    width: calc(100% - 3.75rem);
    margin-top: 3rem;
  }

  @media (max-width: 767px) {
    padding-bottom: 66%;
  }
`

const Claim = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 1.75rem;
  position: absolute;
  bottom: 8.625rem;
  right: 1.875rem;
  left: 1.875rem;
  z-index: 2;
  text-transform: uppercase;
  &:before {
    content: '';
    display: block;
    width: 0.125rem;
    height: 3.75rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    margin: 0 auto 1.875rem;
  }

  @media (max-width: 1023px) {
    bottom: 6.25rem;
    &:before {
      display: none;
    }
  }
`

const CTA = styled(Button)`
  position: absolute;
  bottom: 3.75rem;
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);

  @media (max-width: 1023px) {
    bottom: 1.875rem;
  }
`

const BackgroundImage = styled.div`
  width: 100%;
  padding-bottom: 37%;
  position: absolute;
  bottom: 0;
  left: 0;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.5;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
  }
`
