import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  cta?: ButtonProps
  image?: ImageProps
  services: ServiceProps[]
  subtitle?: string
  title?: string
}

export const ServicesList = memo(function ServicesList({
  cta,
  image,
  services,
  subtitle,
  title,
}: Props) {
  if (services.length < 1) {
    return null
  }

  return (
    <Container row stretch tag="section" wrap>
      <LeftSide>
        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}
      </LeftSide>
      <RightSide dial={4}>
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
        {subtitle ? (
          <FadeInUp>
            <Subtitle>{subtitle}</Subtitle>
          </FadeInUp>
        ) : null}
        <Services row wrap>
          {services.map((item, index) => (
            <Service key={index} {...item} />
          ))}
        </Services>
        {cta ? (
          <FadeInUp>
            <CTA variant="invert" {...cta} />
          </FadeInUp>
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.secondaryDark3};
  padding-top: 7.625rem;

  @media (max-width: 1023px) {
    padding-top: 5rem;
  }
`

const LeftSide = styled.div`
  width: 34%;
  padding-bottom: 45%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const RightSide = styled(FlexBox)`
  width: 66%;
  padding: 0 12.708vw 5rem 9.028vw;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 0 1.875rem 5rem 1.875rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 300;
  letter-spacing: 0.1062rem;
  line-height: 1.25rem;
  opacity: 0.4;

  @media (max-width: 1023px) {
    font-size: 1rem;
  }
`

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.6875rem;
  margin-top: 1.25rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 2.5rem;
    line-height: 2.9375rem;
    margin-top: 1rem;
  }
`

const Services = styled(FlexBox)`
  margin-top: 1.4375rem;

  @media (max-width: 1023px) {
    margin-top: 1.125rem;
  }
`

const CTA = styled(Button)`
  margin-top: 7.5rem;

  @media (max-width: 1023px) {
    margin-top: 5.625rem;
  }
`
